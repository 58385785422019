<template>
    <v-container
        fill-height
        fluid
        :style="{
            backgroundImage: 'url(bg-home-up.png)',
            backgroundOpacity: '0.9',
            width: '100vw'
        }"
    >
        <v-layout align-center justify-center>
            <v-flex xs12 sm10 md10 full-width>
                <v-card class="elevation-3">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title
                            ><v-icon class="ma-2">mdi-account-switch</v-icon
                            >Selecione um novo Apresentante</v-toolbar-title
                        >
                    </v-toolbar>
                    <v-card-text>
                        <v-card-text>
                            Selecione abaixo o apresentante disponível. Assim
                            que o novo apresentante for confirmado clique em
                            "Trocar Apresentante". Os dados do novo apresentante
                            serão carregados no painel.
                        </v-card-text>
                        <v-row>
                            <v-col class="ml-4" md="6">
                                <v-select
                                :items="filtros"
                                label="Filtros"
                                v-model="filterSelected"
                                item-text="name"
                                item-value="value"
                                @change="listarApresentantes"
                                />
                               
                            </v-col>
                        </v-row>
                        <v-form
                            ref="form"
                            @keyup.native.enter="salvarNovoApresentante"
                        >
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    lg="2"
                                    xl="2"
                                    class="mx-auto"
                                >
                                    <v-card-text>
                                        <v-icon>mdi-chevron-right</v-icon>
                                        Selecione um apresentante:</v-card-text
                                    >
                                </v-col>
                                <v-col cols="12" sm="12" lg="6" xl="6">
                                    <v-card-text>
                                        Nome do Apresentante:
                                    </v-card-text>
                                    <v-autocomplete
                                        solo
                                        small
                                        label="Selecione aqui"
                                        prepend-icon="mdi-chevron-down"
                                        v-model="apresentanteSelecionado"
                                        :items="apresentantes"
                                        item-value="cd_apresentante"
                                        item-text="nm_apresentante"
                                        :loading="loading"
                                    >
                                        <template v-slot:item="data">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <span :class="{'text-gray': !data.item.st_ativo}">{{ data.item.nm_apresentante }}</span><br/>
                                                    <div>
                                                        <small>Remessa: </small>
                                                        <small :class="data.item.st_ativo_remessa ? 'text-green': 'text-error'">{{!!data.item.st_ativo_remessa ? 'Ativo': 'Inativo'}}</small>
                                                        <small> | Comando: </small>
                                                        <small :class="!!data.item.st_ativo_comando ? 'text-green': 'text-error'">{{!!data.item.st_ativo_comando ? 'Ativo': 'Inativo'}}</small>
                                                    </div>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" lg="3" xl="3">
                                    <v-card-text class="text-left">
                                        Sigla:
                                    </v-card-text>
                                    <v-select
                                        solo
                                        small
                                        prepend-icon="mdi-chevron-down"
                                        label="Selecione"
                                        v-model="apresentanteSelecionado"
                                        :items="apresentantesOrderByCd"
                                        item-value="cd_apresentante"
                                        item-text="cd_apresentante"
                                        :loading="loading"
                                    ></v-select>
                                </v-col>
                                <v-col lg="1" xl="1"></v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            data-cy="signinSubmitBtn"
                            @click="salvarNovoApresentante"
                        >
                            Trocar Apresentante
                            <v-icon class="ml-1">chevron_right</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Vue from 'vue';
import ApresentanteService from '@/services/apresentanteService';

export default {
    name: 'Login',
    data() {
        return {
            apresentantes: [],
            apresentantesOrderByCd: [],
            apresentanteSelecionado: null,
            newToken: null,
            data: null,
            loading: false,
            filterSelected: {},
            filtros:[
                {
                    name: 'Listar todos os apresentantes', 
                    value: {}
                },
                {
                    name: 'Exibir apenas os com comando ativo', 
                    value: {st_ativo_comando: true}
                },
                {
                    name: 'Exibir apenas os com remessa ativa', 
                    value: {st_ativo_remessa: true}
                },
                {
                    name: 'Exibir apenas os com comando inativo', 
                    value: {st_ativo_comando: false}
                },
                {
                    name: 'Exibir apenas os com remessa inativo', 
                    value: {st_ativo_remessa: false}
                },
                {
                    name: 'Exibir os apresentantes com comando e remessa inativos', 
                    value: {st_ativo_remessa: false, st_ativo_comando: false}
                }
            ]

            
        };
    },
    methods: {
        listarApresentantes() {
            this.loading = true;
            return new Promise((resolve, reject) => {
                try {
                    let apresentanteService = new ApresentanteService(
                        Vue.http,
                        this.$store
                    );
                    apresentanteService.listarApresentantes(this.filterSelected).then(
                        response => {
                            if (response.status == 200) {
                                let arr_apresentantes = [...response.body];
                                this.apresentantesOrderByCd = response.body;

                                arr_apresentantes.sort((a, b) => {
                                    let nomeA = a.nm_apresentante.toUpperCase(); // ignorar maiúsculas e minúsculas
                                    let nomeB = b.nm_apresentante.toUpperCase(); // ignorar maiúsculas e minúsculas
                                    if (nomeA < nomeB) {
                                        return -1;
                                    }
                                    if (nomeA > nomeB) {
                                        return 1;
                                    }
                                    return 0; // nomes iguais
                                });
                                this.apresentantes = arr_apresentantes;
                                this.loading = false;
                            }
                        },
                        error => {
                            console.log(error);
                            this.loading = false;
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        salvarNovoApresentante() {
            const data = this.apresentanteSelecionado;
            localStorage.removeItem('filtroTitulos');
            localStorage.removeItem('resultadoTitulos');
            localStorage.removeItem('outrosFiltros');
            this.$store.commit('setSituacoesTitulo', []);
            this.$store.dispatch('mudarApresentante', {
                data
            });
        }
    },
    mounted() {
        this.listarApresentantes();
    }
};
</script>
<style lang="scss">
.text-green {
    font-weight: bold;
    color: green;
}
.text-error {
    font-weight: bold;
    color: red;
}
.text-gray {
    color: gray;
}
</style>
